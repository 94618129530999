import React, { useState } from "react";
import { Link } from "gatsby";

function ServicesTabs({
  data,
  tabsData,
  background = "bg-gradient-to-br from-[#EEF7FF] to-white",
  tabsName = [
    "Legal",
    "Medical",
    "Financial",
    "Marketing",
    "Technical",
    "General Business",
  ],
}) {
  const [tab, setTab] = useState("tab1");
  return (
    <div className={`px-4 2xl:px-0 py-10 lg:py-24 ${background}`}>
      <div className="w-full max-w-6xl mx-auto">
        <h2 className="text-base lg:text-3xl leading-[55px] review_title text-center xs:font-secondary font-semibold pb-2">
          Document Translation Services for Your Every Need
        </h2>
        <h3 className="text-5xl leading-[60px] text-[#012458] text-center xs:font-secondary font-bold">
          What is the purpose of your translation?
        </h3>
        <ul className="flex flex-wrap gap-y-6 gap-2 md:gap-4 lg:gap-5 justify-center pt-[2.5rem] pb-[5rem]">
          {tabsName?.map((item, i, arr) => {
            return (
              <li className="flex items-center gap-1 md:gap-3 lg:gap-5" key={i}>
                <p
                  className={`text-sm sm:text-base uppercase font-opensans font-semibold cursor-pointer transition-colors duration-300 ${
                    tab === `tab${i + 1}` ? "text-black" : "text-[#737373]"
                  }`}
                  onClick={() => setTab(`tab${i + 1}`)}
                >
                  {item}
                </p>
                {i < arr.length - 1 ? (
                  <span className="text-[#737373]">|</span>
                ) : null}
              </li>
            );
          })}
        </ul>
        {tabsData?.map((item, i) => {
          return (
            <div
              key={i}
              className={`services-tab grid-cols-1 lg:grid-cols-2 items-center gap-20 bg-white p-16 rounded-[32px] shadow-xl ${
                tab === `tab${i + 1}` ? "grid" : "hidden"
              }`}
            >
              <div>
                <h4 className="text-3xl leading-[55px] text-black xs:font-secondary font-semibold pb-2">
                  {item.title}
                </h4>
                <p className="text-base leading-7 text-[#3D3F42] pb-6">
                  {item.description}
                </p>
                {item.cta ? (
                  <Link
                    to={item.cta.href}
                    className="text-base bg-gradient-to-r from-[#F93443] to-[#FF6E1F] text-20 xs:font-secondary font-semibold underline pb-2 inline-block text-transparent bg-clip-text  decoration-sky-500"
                  >
                    {/* <p> {item.cta.title}</p> */}
                    <div className="gradient-underline">
                      {item.cta.title} &#x2192;
                    </div>
                  </Link>
                ) : null}
              </div>
              <img
                src={item.img}
                alt={item.title}
                className="hidden lg:block"
              />
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default ServicesTabs;
