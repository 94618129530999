import React from "react";
import Text from "components/Text";
import { Link } from "gatsby";

function TranslationLanguages({
  data,
  background = "bg-gradient-to-br from-[#E6F8FD] to-[#F4F9FE]",
}) {
  return (
    <div className={`px-4 2xl:px-0 py-10 lg:py-28 ${background}`}>
      <div className="w-full max-w-6xl mx-auto">
        <Text
          as="h3"
          className="text-3xl leading-[55px] text-[#334CE5] text-center xs:font-secondary font-semibold pb-2"
        >
          {data.subTitle}
        </Text>
        <Text
          as="h3"
          className="text-5xl leading-[60px] text-[#001635] text-center xs:font-secondary font-bold pb-7"
        >
          {data.title}
        </Text>
        <Text
          as="p"
          className="text-lg leading-8 text-[#3D3F42] text-center font-opensans pb-11 max-w-5xl mx-auto"
        >
          {data.description}
        </Text>
        <div className="max-w-6xl p-8 lg:p-16 rounded-2xl bg-white mx-auto grid grid-cols-2 sm:grid-cols-3 lg:grid-cols-5 gap-6 md:gap-14 py-11">
          {data.languages.map((language, i) => {
            return (
              <div className="flex items-center gap-4" key={i}>
                <img src={language.icon} alt={language.title} />
                <Link
                  to={language.href}
                  className="text-xl text-black hover:text-[#F93443] font-opensans font-semibold transition-colors"
                >
                  {language.title}
                </Link>
              </div>
            );
          })}
        </div>
        <div className="flex items-center justify-center pt-4">
          <Link
            to="/language-translation-service.php"
            className="text-lg text-[#012458] text-center xs:font-secondary font-semibold underline"
          >
            See all languages
          </Link>
        </div>
      </div>
    </div>
  );
}
export default TranslationLanguages;
