import React from "react";
import Text from "components/Text";

function TranslationProcess({
  data,
  background = "bg-translationProcess",
  textCenter = true,
}) {
  return (
    <div
      className={`px-4 2xl:px-0 pt-24 lg:pt-10 ${
        data.paddingTop ? " lg:pt-0" : "lg:pt-28"
      }  ${background} bg-cover`}
    >
      <div
        className={`w-full max-w-6xl mx-auto bg-white px-12 ${
          data.paddingTop ? "pt-0" : "pt-16"
        }  pb-8 rounded-t-[40px]`}
      >
        {data.title ? (
          <Text
            as={data?.titleTag ? data?.titleTag : "h4"}
            highlightedText={data?.highlightWords}
            highlightedColor={data?.highlightedColor}
            className="text-45 leading-[70px] text-[#012458] text-center xs:font-secondary font-bold pb-6"
          >
            {data.title}
          </Text>
        ) : null}
        {data.description ? (
          <Text
            as="p"
            className="text-lg leading-8 text-[#3D3F42] text-center font-opensans pb-8"
          >
            {data.description}
          </Text>
        ) : null}
        <ul className="grid grid-cols-1 md:grid-cols-3 pt-16 gap-x-6 gap-y-12 md:gap-x-12 md:gap-y-16 lg:gap-x-20 ">
          {data.process.map((item, i) => {
            return (
              <ol
                className={`flex flex-col gap-2 ${
                  textCenter ? "items-center" : " "
                }`}
                key={i}
              >
                <div>
                  <img src={item.icon} alt= {item.title} />
                </div>
                <p
                  className={`${
                    data.isLarge ? "text-24" : "text-base"
                  } text-[#012458] xs:font-secondary font-semibold ${
                    textCenter ? "text-center" : ""
                  }`}
                >
                  {item.title}
                </p>
                <p
                  className={`${
                    data.isLarge ? "text-base leading-[26px]" : "text-sm"
                  } text-black font-opensans ${
                    textCenter ? "text-center" : ""
                  }`}
                >
                  {item.description}
                </p>
              </ol>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
export default TranslationProcess;
