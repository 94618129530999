import { Link } from "gatsby";
import React from "react";
// import { errorMessageEmail, patternEmail } from "utils/form-validation";

const ContactUs = () => {
  return (
    <div className="mt-16 lg:mt-0 bg-gradient-to-r from-[#3B37E1] to-[#00D1FF] h-auto  py-14 sm:py-0">
      <div className="w-9/12 m-auto flex flex-col justify-center items-center gap-y-4 py-16">
        <h2 className="font-primary text-[36px] text-white font-bold">
          Check Out Our Cost-Effective Pricing
        </h2>
        <p className="text-white font-opensans">
          Relay your words with efficiency, accuracy, and cultural relevance
          every time with Tomedes’ affordable translation services.
        </p>
        {/* <div className="bg-white w-auto sm:w-[434px] sm:h-[64px] rounded-lg flex items-center pr-6">
          <input
            type="email"
            placeholder="Enter your email address here"
            className="w-full h-full rounded-lg pl-4 focus:outline-none"
            // {...register("email", {
            //   required: true,
            //   pattern: patternEmail,
            // })}
          />
          // {errors.email && (
          //     <span className="text-red text-xs pt-2">
          //      {errorMessageEmail}
          //     </span>
          //   )}
          <button className="text-3xl text-[#F93443] mb-[10px]">
            &#x2192;
          </button>
        </div> */}
        <div>
          <Link
            to="/quote"
            className="flex justify-center space-x-2 items-center w-72 uppercase text-base font-semibold text-[#012458] bg-white rounded-full py-4 px-8"
          >
            <p>Get a free quote</p>
            <p className="text-2xl font-semibold">&#x2192;</p>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
