import React from "react";
import Text from "components/Text";
import { Link } from "gatsby";

function Standards({
  data,
  background = "bg-gradient-to-br from-[#E6F8FD] to-[#F4F9FE]",
}) {
  return (
    <div className={`px-4 2xl:px-0 py-20 lg:py-28 ${background}`}>
      <div className="w-full max-w-7xl mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-20 lg:gap-52">
          <div>
            {data.subTitle ? (
              <Text
                as="h2"
                highlightedText={data?.subTitleHighlightWords}
                highlightedColor={data?.subTitleHighlightedColor}
                className="text-base lg:text-26 review_title xs:font-secondary font-semibold pb-3"
              >
                {data.subTitle}
              </Text>
            ) : null}
            <Text
              as={data?.titleTag ? data?.titleTag : "h3"}
              highlightedText={data?.highlightWords}
              highlightedColor={data?.highlightedColor}
              className="text-45 leading-[55px] text-[#012458] xs:font-secondary font-bold pb-6"
            >
              {data.title}
            </Text>
            <Text
              as="p"
              className="text-lg leading-8 text-[#3D3F42] font-opensans pb-8"
            >
              {data.description}
            </Text>
            {data.cta ? (
              <Link
                to={data.cta.href}
                className="text-base bg-gradient-to-r from-[#F93443] to-[#FF6E1F] text-20 xs:font-secondary font-semibold underline pb-2 inline-block text-transparent bg-clip-text  decoration-sky-500"
              >
                {/* <p> {item.cta.title}</p> */}
                <div className="gradient-underline">
                  {data.cta.title} &#x2192;
                </div>
              </Link>
            ) : // <Link
            //   to={data.cta.href}
            //   className="block text-lg text-[#012458] xs:font-secondary font-semibold underline pb-2"
            // >
            //   {data.cta.title}
            // </Link>
            null}
          </div>
          <div className="flex flex-col gap-14">
            {data.standards.map((item, i) => {
              return (
                <div key={i}>
                  <div className="flex items-center gap-8 pb-4">
                    <img src={item.icon} alt={item.title} />
                    <Text
                      as="p"
                      className="text-lg text-black font-opensans font-semibold"
                    >
                      {item.title}
                    </Text>
                  </div>
                  <Text
                    as="p"
                    className="text-base text-[#3D3F42] font-opensans"
                  >
                    {item.description}
                  </Text>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Standards;
