import React, { useState } from "react";
import rightArrowWhiteSvg from "assets/translation-services/right-arrow-white.svg";
import rightArrowBlackSvg from "assets/translation-services/right-arrow-black.svg";
import Text from "components/Text";
import QuotePopUp from "components/popup/quotePopUp/index";
import OptimizedImage from "hooks/OptimizedImage";
import { Link } from "gatsby";
import EnquiryForm from "components/EnquiryForm";
import Servicesicon from "components/homepage/translationServices/servicesicon";
function TwoCol({
  data,
  background = "bg-white",
  order = "order-last",
  isBanner = false,
}) {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={`px-4 2xl:px-0 lg:py-28 ${
        isBanner ? "py-10" : "py-20"
      } ${background} ${
        data.isHero &&
        "bg-gradient1  relative bg-desktop bg-cover   sm:px-0 pt-5 md:pt-16 pb-8"
      } ${data.paddingBottom && "lg:pb-0"}`}
    >
      <div
        className={` ${data.isHero ? "gradient px-2 sm:mt-0 mt-[100px]" : ""}`}
      >
        <div className="w-full max-w-7xl mx-auto">
          <div
            className={`lg:flex justify-between ${
              data.isHero ? "items-start" : "items-center"
            } gap-28`}
          >
            <div className="lg:w-1/2">
              {data.isHero && (
                <div className="pb-5">
                  <ul className=" gap-8 lg:flex ">
                    <li className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                          fill="#4285F4"
                        />
                      </svg>
                      <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                        Available Online 24/7
                      </p>
                    </li>

                    <li className="flex items-center my-4 lg:my-0">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                          fill="#4285F4"
                        />
                      </svg>
                      <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                        1-Year Guarantee
                      </p>
                    </li>

                    <li className="flex items-center">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="19"
                        viewBox="0 0 18 19"
                        fill="none"
                      >
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M12.445 17.6564C11.3529 18.1088 10.1821 18.3409 9 18.3393C7.81788 18.3409 6.64712 18.1087 5.555 17.6564C4.46287 17.204 3.4709 16.5402 2.63611 15.7032C1.79912 14.8685 1.13536 13.8765 0.682965 12.7844C0.230575 11.6922 -0.00152412 10.5215 7.53144e-06 9.33936C-0.00149947 8.15724 0.230611 6.98648 0.683 5.89435C1.13539 4.80222 1.79914 3.81026 2.63611 2.97546C3.4709 2.13849 4.46287 1.47474 5.555 1.02236C6.64712 0.569966 7.81788 0.337856 9 0.339363C10.1821 0.337831 11.3529 0.56993 12.445 1.02232C13.5372 1.47471 14.5291 2.13847 15.3639 2.97546C16.2009 3.81026 16.8646 4.80222 17.317 5.89435C17.7694 6.98648 18.0015 8.15724 18 9.33936C18.0015 10.5215 17.7694 11.6922 17.317 12.7844C16.8646 13.8765 16.2009 14.8685 15.3639 15.7032C14.5291 16.5402 13.5372 17.204 12.445 17.6564ZM14.1364 7.27575C14.4879 6.92427 14.4879 6.35443 14.1364 6.00295C13.7849 5.65148 13.2151 5.65148 12.8636 6.00295L8.09999 10.7666L6.03639 8.70295C5.68492 8.35148 5.11507 8.35148 4.7636 8.70295C4.41213 9.05442 4.41213 9.62427 4.7636 9.97574L7.4636 12.6757C7.81507 13.0272 8.38492 13.0272 8.73639 12.6757L14.1364 7.27575Z"
                          fill="#4285F4"
                        />
                      </svg>
                      <p className="text-base text-[#000C27] font-medium pl-2 lg:text-left text-center">
                        95,000 Business Customers
                      </p>
                    </li>
                  </ul>
                </div>
              )}
              {data.subTitle ? (
                <Text
                  as="h2"
                  highlightedText={data?.subTitleHighlightWords}
                  highlightedColor={data?.subTitleHighlightedColor}
                  className="text-base lg:text-26 review_title xs:font-secondary font-semibold pb-3"
                >
                  {data.subTitle}
                </Text>
              ) : null}
              <Text
                as={data?.titleTag ? data.titleTag : "h3"}
                highlightedText={data?.highlightWords}
                highlightedColor={data?.highlightedColor}
                className="text-45 leading-[55px] text-black xs:font-secondary dsfdsfds font-bold pb-6"
              >
                {data.title}
              </Text>
              <Text
                as="p"
                className={`text-lg leading-8 text-[#3D3F42] font-opensans ${
                  data.cta3 && "pb-6"
                }`}
              >
                {data.description}
              </Text>
              <div
                className={`${
                  data.cta2
                    ? "flex flex-col sm:flex-row sm:items-center gap-6"
                    : " "
                } ${data.cta ? "pt-6" : data.cta2 ? "pt-6" : " "}`}
              >
                {data.cta ? (
                  <Text
                    as={data.cta.link ? "a" : "button"}
                    href={data.cta.link ? data.cta.link : ""}
                    className=" uppercase rounded-full bg-orange hover:bg-newOrange-100
                    text-white cursor-pointer text-17 font-semibold py-4 px-8
                    w-[253px] text-center transition-colors duration-300 inline-flex
                    items-center
                    justify-center
                    gap-2"
                  >
                    {data.cta.title}
                    <img src={rightArrowWhiteSvg} alt="cta" />
                  </Text>
                ) : null}

                {data.cta3 ? (
                  <Link
                    to={data.cta3.href}
                    className="text-base bg-gradient-to-r from-[#F93443] to-[#FF6E1F] text-20 xs:font-secondary font-semibold underline pb-2 inline-block text-transparent bg-clip-text  decoration-sky-500"
                  >
                    <div className="gradient-underline">
                      {data.cta3.title} &#x2192;
                    </div>
                  </Link>
                ) : null}
              </div>
            </div>

            {!data.isHero && (
              <div className={`lg:w-1/2 hidden lg:block ${order}`}>
                <img
                  src={data.img}
                  alt={
                    data.isHero
                      ? data.title
                      : !data.subTitle
                      ? data.title
                      : data.subTitle
                  }
                  className="w-full max-w-full"
                />
              </div>
            )}

            <div className="mt-16 lg:mt-0">
              {data.isHero && (
                <EnquiryForm
                  title="Get in touch"
                  highlightWords="Get in touch"
                  highlightedColor="orange"
                  subtitle="We guarantee a reply in a few minutes."
                />
              )}
            </div>
          </div>
        </div>
        {data.isHero && (
          <div className="w-full pt-[80px] max-w-7xl mx-auto pb-[60px]">
            <Servicesicon />
          </div>
        )}
        <QuotePopUp open={open} setOpen={setOpen} />
      </div>
    </div>
  );
}
export default TwoCol;
