import Layout from "components/layout";
import SEO from "components/seo";
import TranslationServices from "components/TranslationServices";
import React from "react";

const index = () => {
  return (
      <TranslationServices />   
  );
};

export default index;
