import legalSvg from "assets/translation-services/legal.svg";

export const tabsData = [
  {
    title: "Legal",
    description:
      "Whether you’re a law firm working with international clients or a website owner navigating international compliance, we specialize in the exacting and meticulous process of ensuring your project meets the standards for your target audience.",
    cta: {
      title: "Learn more",
      href: "https://www.tomedes.com/legal-translation.php",
    },
    img: "https://skins.tomedes.com/S3UploadedFiles/1665744486legal-content.png",
  },
  {
    title: "Medical",
    description:
      "The ever-growing medical sector has a demonstrated need for accurate and culturally pertinent translation services. We bridge the language gap by offering fast and affordable translations by specialized linguists.",
    cta: {
      title: "Learn more",
      href: "https://www.tomedes.com/medical-translation.php",
    },
    img: "https://skins.tomedes.com/S3UploadedFiles/1665744563medical-content.png",
  },
  {
    title: "Financial",
    description:
      "We started with translating prospectuses and financial reports, but the ever-evolving landscape of finance has led us to work with more complex environments and new terminologies to keep up with the innovative language of the financial sector.",
    cta: {
      title: "Learn more",
      href: "https://www.tomedes.com/financial-translation.php",
    },
    img: "https://skins.tomedes.com/S3UploadedFiles/1665744591financial-content.png",
  },
  {
    title: "Marketing",
    description:
      "There is no translation service that requires the human touch more than the marketing domain. Tomedes’ human translators use their in-depth understanding of the target market and creative knowhow to adapt your message and ensure it speaks volumes.",
    cta: {
      title: "Learn more",
      href: "https://www.tomedes.com/marketing-translation.php",
    },
    img: "https://skins.tomedes.com/S3UploadedFiles/1665746969marketing-content.png",
  },
  {
    title: "Technical",
    description:
      "Our global economy has made products and services accessible everywhere, so reliable translations are essential to keep up with the international market. Our human translators are specialized in specific industries to ensure terminological compliance for any kind of technical need.",
    cta: {
      title: "Learn more",
      href: "https://www.tomedes.com/technical-translation.php",
    },
    img: "https://skins.tomedes.com/S3UploadedFiles/1665746998technical-content.png",
  },
  {
    title: "General Business",
    description:
      "Every business has its own process and language, which varies with every region and industry. We go beyond the linguistic realm to adapt your source documentation to your target culture or country.",
    cta: {
      title: "Learn more",
      href: "https://www.tomedes.com/business-translation.php",
    },
    img: "https://skins.tomedes.com/S3UploadedFiles/1665747027general-business.png",
  },
];
