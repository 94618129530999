// import React from "react";
// import { Link } from "gatsby";
// import Click from "assets/click.svg";
// import Layout from "../layout";
// import Seo from "../seo";
// import Hero from "assets/smart-casual.svg";
// import QualityGurantee from "components/YearGuarantee/quality-gurantee";
// import ClientWorldwide from "./clientWorldwide";
// import AdvanceTranslation from "./advanceTranslation";
// import DocumetTranslation from "./documentTranslation";
// import SimpleStep from "./simpleStep";
// import Uploading from "assets/uploading.svg";
// import SimpleStep1 from "./simplestep1";
// import Priceimg from "assets/pricing.svg";
// import Fastdeliver from "assets/fastdelivery.svg";
// import Pricing from "./pricing";
// import CustomerFirst from "./customerfirst";
// import ExceptionalQuality from "components/FastDelivery/exceptionalQuality";
// import { Slides, TestimonilaData } from "./config";
// import OneStopSolution from "./oneStopSolution";
// import ClientTestimonial from "components/YearGuarantee/clientTestimonial";
// import Deeper from "./deeper";
// import { CustomerFirstdata } from "./config";

// // import QualityGurantee from "./quality-gurantee";
// function TranslationServices() {
//   const backgroundImageStyle = {
//     backgroundImage: `url(${Hero})`,
//     backgroundSize: "cover",
//     backgroundPosition: "center",
//     height: "auto",
//   };
//   const srvicedata = [
//     { title: "Fast Delivery" },
//     { title: "ATA Membership" },
//     { title: "Guaranteed USCIS Acceptance" },
//     { title: "ISO Certified" },
//   ];

//   const qualityGuranteeContent = (
//     <div className="max-w-5xl mx-auto grid md:grid-cols-2 lg:grid-cols-4 gap-4 text-white mt-12">
//       {srvicedata.map((item, i) => (
//         <div className="flex gap-2" key={i}>
//           <div className="min-w-12">
//             <img src={Click} alt="" className="min-w-6 mt-2" />
//           </div>
//           <p>{item.title}</p>
//         </div>
//       ))}
//     </div>
//   );
//   const buttonContent = (
//     <button className="bg-[#FF7B16] text-lg text-center px-4 py-2 rounded-md font-semibold leading-6 text-white">
//       Lock in Your Perfect Price Now!
//     </button>
//   );
//   return (
//     <Layout>
//       <Seo
//         title="Translation Services"
//         description="Tomedes provides translation services in all major languages. We translate any type of content, including documents, video, and websites."
//         slug="/translation-services"
//       />
//       {/* <BannerCenter /> */}
//       <div className="lg:hidden w-full px-4 2xl:px-0 py-7 shadow-2xl">
//         <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
//           <Link to="/">
//             <img
//               src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
//               alt="home"
//             />
//           </Link>
//           <img
//             src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
//             alt="navigate"
//           />
//           <span>Translation Services</span>
//         </div>
//       </div>

//       <section className="px-2 sm:px-0  py-12 " style={backgroundImageStyle}>
//         <QualityGurantee
//           heading=" Online Translation Services Perfected With Care"
//           subheading="At Tomedes, we're redefining professional translation by placing you,
//           our valued customer, at the center of everything we do. Now, it’s
//           easier than ever to access translation services on your terms. We
//           offer 24/7 customer care, lightning-fast deliveries, and
//           cost-efficient solutions."
//           qualityGuranteeContent={qualityGuranteeContent}
//           button={buttonContent}
//         />
//       </section>

//       <div className="hidden lg:block w-full px-4 2xl:px-0 py-7 ">
//         <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
//           <Link to="/">
//             <img
//               src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
//               alt="home"
//             />
//           </Link>
//           <img
//             src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
//             alt="navigate"
//           />
//           <span>Translation Services</span>
//         </div>
//       </div>

//       <ClientWorldwide />
//       <AdvanceTranslation />
//       <DocumetTranslation />
//       <SimpleStep
//         heading="How to Order a Translation Online in 3 Simple Steps"
//         step="Step 1"
//         title="Upload Your Project"
//         description="You can also include project details such as the specific type of translation service and subject of the text at this stage."
//         image={Uploading}
//       />
//       <SimpleStep1
//         heading=""
//         step="Step 2"
//         title="Select Your Pricing Plan"
//         description="Pick your pricing option, (best/fast/tailored)then pay securely online—either upfront or post-delivery. Your choice!"
//         image={Priceimg}
//       />
//       <SimpleStep
//         heading=""
//         step="Step 3"
//         title="Fast Delivery"
//         description="After a strict quality review and editing process, you’ll receive accurate translations for your review and approval— right on schedule.  Enjoy peace of mind with our 1-Year Quality Guarantee!"
//         image={Fastdeliver}
//       />
//       <Pricing />
//       <CustomerFirst
//         title="Language Translation Service: Always Customer-First"
//         description="At the heart of our translation service is a commitment to
//           customer-centric excellence. Beyond just accurate translations, we
//           promise meaningful interactions, round-the-clock support, swift
//           deliveries, and our unparalleled 1-year guarantee."
//         heading=""
//         customerFirstdata={CustomerFirstdata}
//       />
//       <ExceptionalQuality
//         heading="Versatile Translation for Professional and Personal Needs"
//         subheading="From personal letters to academic research, consider us your language success partner, tailoring our solutions to your every need."
//         Slides={Slides}
//       />
//       <OneStopSolution />
//       <ClientTestimonial
//         text="Voices of Our Valued Clients"
//         description="Discover firsthand accounts of how our translation services have impacted businesses and individuals alike."
//         btntext="Read More Testimonials"
//         testimoniladata={TestimonilaData}
//       />
//       <Deeper />
//     </Layout>
//   );
// }
// export default TranslationServices;

import React from "react";
import BannerCenter from "./BannerCenter";
import ServicesTabs from "./ServicesTabs";
import { tabsData } from "./tabsData";
import TwoCol from "./TwoCol";
import {
  twoColBannerData,
  standardsData,
  twoColData,
  twoColData2,
  twoColData3,
  translationProcessData,
  translationProcessData2,
  translationLanguagesData,
} from "./config";
import TranslationLanguages from "./TranslationLanguages";
import ClientsReviews from "./ClientsReviews";
import { Link } from "gatsby";
import Standards from "./Standards";
import TranslationProcess from "./TranslationProcess";
import ContactUs from "./ContactUs";
import Layout from "../layout";
import Seo from "../seo";

function TranslationServices() {
  return (
    <Layout>
      <Seo
        title="Translation Services"
        description="Tomedes provides translation services in all major languages. We translate any type of content, including documents, video, and websites."
        slug="/translation-services"
      />
      {/* <BannerCenter /> */}
      <div className="lg:hidden w-full px-4 2xl:px-0 py-7 shadow-2xl">
        <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
          <Link to="/">
            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
              alt="home"
            />
          </Link>
          <img
            src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
            alt="navigate"
          />
          <span>Translation Services</span>
        </div>
      </div>
      <TwoCol
        data={twoColBannerData}
        background="bg-[#FBFCFF]"
        isBanner={true}
      />
      <div className="hidden lg:block w-full px-4 2xl:px-0 py-7 shadow-2xl">
        <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
          <Link to="/">
            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
              alt="home"
            />
          </Link>
          <img
            src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
            alt="navigate"
          />
          <span>Translation Services</span>
        </div>
      </div>
      <Standards data={standardsData} />
      <TwoCol order="order-first" data={twoColData} />
      <TranslationProcess data={translationProcessData} />
      <ServicesTabs tabsData={tabsData} />
      <TwoCol
        order="order-first"
        data={twoColData2}
        background="bg-white pb-0"
      />
      <TranslationProcess
        data={translationProcessData2}
        background="bg-white xs:pt-4 xs:pb-[5rem]"
        textCenter={false}
      />
      <TranslationLanguages data={translationLanguagesData} />
      <TwoCol
        order="order-first"
        data={twoColData3}
        background="bg-white pb-0"
      />
      <ContactUs />
      <ClientsReviews />
    </Layout>
  );
}
export default TranslationServices;
