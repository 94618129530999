import { faHeading } from "@fortawesome/free-solid-svg-icons";
import Hebrew from "assets/images/header-images/hebrew.svg";
import Legal from "assets/legal.svg";
import Technical from "assets/technical.svg";
import Financial from "assets/financial.svg";
import Medical from "assets/medical.svg";
import Business from "assets/business.svg";
import Marketing from "assets/marketing.svg";
import Continuous from "assets/continuous.svg";
import Delivery from "assets/deliveryvan.svg";
import Support from "assets/support.svg";
import Guaranteeicon from "assets/guaranteeicon.svg";
import Personaltrans from "assets/personaltrans.svg";
import Academytrans from "assets/academytrans.svg";
import Patenttrans from "assets/patenttrans.svg";
import Litracytrans from "assets/litracytrans.svg";
import Governer from "assets/governer.svg";
import Abandoned from "assets/abandoned.svg";
import Jn from "assets/jn.svg";
import Jg from "assets/jg.svg";
import Am from "assets/am.svg";

export const twoColBannerData = {
  title: "Translation Services",
  titleTag: "h1",
  highlightWords: [],
  highlightedColor: "",
  description:
    "At Tomedes, we are committed to delivering language translation services that go beyond words and phrases. You can expect us to deliver high-quality solutions for a wide range of industries. We are available by phone or online 24/7 for even the most urgent translation needs. Our comprehensive document translation solutions should help you expand and navigate the greater global market.",
  img: "https://tomedes.gumlet.io/S3UploadedFiles/1665731630banner.png",
  cta: {
    title: "TRANSLATE NOW",
    link: "/quote",
    backgroundColor: "#ff6600",
    textColor: "#FFFFFF",
  },
  cta2: {
    title: "CONTACT US",
  },
  isHero: true,
};

export const standardsData = {
  title: "Count on Tomedes to Deliver High-Quality Services Every Time",
  highlightWords: [],
  subTitle: "Translation Services You Can Trust",
  highlightedColor: "",
  titleTag: "h3",
  // subTitleHighlightWords: ["Trust"],
  // subTitleHighlightedColor: "black",
  // cta: { title: "Explore More", href: "/quote" },
  description:
    "With Tomedes, you can be sure every translation we provide meets the highest standards around. We are proud members of the American Translators Association, and our foreign language translation services are ISO certified. Plus, you can count on us any time of the day, with our 24/7 customer support online.",
  // cta: {
  //   href: "/",
  //   title: "Explore our localization services",
  // },
  standards: [
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1665989950ata.svg",
      title: "American Translators<br />Association",
      description: "Accredited member of the American Translator’s Association",
    },
    {
      icon: "https://tomedes.gumlet.io/assets/quality-policy/accreditation/17100.webp",
      title: "ISO 17100:2015<br />Translation Services",
      description:
        "Translation services that adhere to international standards",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1665990523247.svg",
      title: "Available Online<br />24/7",
      description: "Order online 24/7 via our website, phone, or email",
    },
  ],
};

export const twoColData = {
  title: "Build Genuine Connections With Translations by Humans for Humans",
  highlightWords: [],
  highlightedColor: "",
  subTitle: "Human Translation Services by Experts",
  titleTag: "h3",
  // subTitleHighlightWords: ["content"],
  // subTitleHighlightedColor: "black",
  description:
    "At Tomedes, we understand that nothing is more essential than relaying your message to the world. Human translation services are the best way to go about this because they rely on life and language experience to communicate the subtleties of your source text to a larger audience.<br/> Because we understand that the most precious relationships are built on trust, you can rely on us to provide complete transparency every step of the translation process. What’s our main goal? Ensuring your meaning goes beyond linguistic and cultural borders to reach the people you need. We look forward to working with you.",
  img: "https://tomedes.gumlet.io/S3UploadedFiles/1665742327pic_3.png",
};

export const twoColData2 = {
  paddingBottom: true,
  title: "Document Translations That Adapt to Any Medium",
  // subTitle: "Insert content here",
  description:
    "We support any medium you may need to transform for your global audience. Tomedes can quickly and efficiently deliver expertly translated text in hard copy form, PDF files, website content, apps, software, and more. And if you need to incorporate your content into multiple media, we are here to serve you. This means we can adapt your translated documents to one or several media so your content is geared-up on all fronts.",
  img: "https://tomedes.gumlet.io/S3UploadedFiles/1665742938block6.png",
};

export const twoColData3 = {
  title: "How we Hire Translators",
  description:
    "Tomedes’ human translators are hired following a rigorous process that evaluates linguistic skill of both the source and target language as well as their understanding of the compatible cultures. Our vendor management team moreover assesses the specific industry knowledge of each language provider through strict guidelines and continuous terminological reviews. This process ensures all our translators are committed linguists who have the abilities and drive to deliver quality translations for all your language needs. Meet our translators from all over the world today.",
  img: "https://tomedes.gumlet.io/S3UploadedFiles/1666089297pic_7.png",
  // cta3: { title: "Conatct Us", href: "/contactus.php" },
};

export const translationProcessData = {
  title: "A Translation Process with You in Mind",
  highlightWords: [],
  titleTag: "h2",
  highlightedColor: "",
  description:
    "Our commitment to affordable and quality professional document translations has prompted us at Tomedes to optimize our translation workflow while still applying the very best translation practices in the industry. This includes working with expert human translators, linguists, and project managers ensuring quality every step of the way.",
  process: [
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1665999391icon-1.svg",
      title: "Identify Client Requirements",
      description:
        "Our account manager will identify the needed resources for your translation project.",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666007242icon-2.svg",
      title: "Translation",
      description:
        "Our professional human translator will translate your source text into the target language.",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666007679icon-3.svg",
      title: "Proofreading",
      description:
        "Our proofreader will establish that there is terminological and linguistic accuracy throughout your target text.",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666007340icon-4.svg",
      title: "Editing",
      description:
        "Our editor will verify stylistic and grammatical guidelines relevant to your target audience.",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666007352icon-5.svg",
      title: "Review",
      description:
        "Our linguist will check to confirm the final output of your translation is the very best that it can be.",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666007364icon-6.svg",
      title: "Delivery",
      description:
        "Once we have made sure steps one to five are completed and approved, our project managers will deliver your project to you. ",
    },
  ],
};

export const translationProcessData2 = {
  paddingTop: true,
  description: null,
  isLarge: true,
  process: [
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666088107document.svg",
      title: "Document",
      description:
        "Years of experience has allowed us to provide affordable yet high-quality translations that can be delivered in both hard and digital copy. Any handwritten documents can be easily transformed, too: just take a photo and send it our way.",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666088202language.svg",
      title: "Website",
      description:
        "Tomedes examines the intricacies of language, standards for optimal user experience, and methods for successful international reach to deliver completely outstanding content pertinent for your industry. So, you are guaranteed an affordable translation with a global reach every time.",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666088214web.svg",
      title: "Audiovisual",
      description:
        "Tomedes’ translators possess the special skills required to transform audio and visual content so your message can be fully received by followers all around. You can count on us to accurately translate any type of audiovisual content.",
    },
  ],
};

export const translationLanguagesData = {
  title: "Translate into 120+ Languages",
  highlightWords: [],
  highlightedColor: "",
  // subTitle: "Insert content here",
  // subTitleHighlightWords: ["content"],
  // subTitleHighlightedColor: "black",
  description:
    "There is almost no language Tomedes cannot handle, thanks to our network of native translators from around the world. Among the languages we work with, the most common are:",
  languages: [
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666013950spain.svg",
      href: "/english-to-spanish-translation-service.php",
      title: "Spanish",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666014115china.svg",
      href: "/languages/chinese",
      title: "Chinese",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666014131arabia.svg",
      href: "/languages/arabic",
      title: "Arabic",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666014149france.svg",
      href: "/languages/french",
      title: "French",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666014188italy.svg",
      href: "/languages/italian",
      title: "Italian",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666014214german.svg",
      href: "/languages/german",
      title: "German",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666014319korea.svg",
      href: "/languages/korean",
      title: "Korean",
    },
    {
      icon: Hebrew,
      href: "/languages/hebrew",
      title: "Hebrew",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666014356japan.svg",
      href: "/languages/japanese",
      title: "Japanese",
    },
    {
      icon: "https://tomedes.gumlet.io/S3UploadedFiles/1666014387portugal.svg",
      href: "/languages/portuguese",
      title: "Portuguese",
    },
  ],
};
export const AdvanceTranslationdata = [
  {
    heading: "Expertise-Driven Human Translation",
    subheading:
      "Dive into our professional translation services, where human linguists masterfully capture cultural and technical nuances.",
    title: "",
  },

  {
    heading: "Enhanced Machine Translation Solutions",
    subheading:
      "We blend advanced tech speed with human-verified quality. Our expert post-editing ensures accuracy and speed —",
    title: "100% Quality Guaranteed.",
  },

  {
    heading: "Need a Personalized Translation Workflow?",
    subheading:
      "Let us build a streamlined solution just for you. Contact our specialists today to discuss your requirements and explore alternative translation workflows. .",
    title: "",
  },
];

export const DocumetTranslationdata = [
  {
    imgUrl: Legal,
    heading: "Legal",
    subheading:
      "Entrust critical documents to legal translators well-acquainted with the legal jargon in source & target languages.",
    btn: "Secure Reliable Legal Translations",
  },
  {
    imgUrl: Technical,
    heading: "Technical",
    subheading:
      "Access tech experts who understand how critical every detail is to complex projects like yours.",
    btn: "Unlock Advanced Tech Expertise ",
  },
  {
    imgUrl: Financial,
    heading: "Financial",
    subheading:
      "Keep sensitive financial documents safe with the strict confidentiality and pinpoint precision of our expert translations.",
    btn: "Experience Financial Clarity",
  },
  {
    imgUrl: Medical,
    heading: "Medical",
    subheading:
      "Rely on our compliant healthcare translations, ensuring patient care and safety.",
    btn: "Uncover Medical Accuracy",
  },
  {
    imgUrl: Business,
    heading: "Business",
    subheading:
      "Close the deal with translators who understand cultural norms & business etiquette.",
    btn: "Bridge Business Gaps",
  },
  {
    imgUrl: Marketing,
    heading: "Marketing",
    subheading:
      "Captivate your target audience with natural-sounding translations that tell your brand story & showcase your value. ",
    btn: "Amplify Your Brand Voice",
  },
];
export const Pricingdata = [
  {
    heading: "Best Price",
    price: "24",
    description: "Most Budget-Friendly",
    description1: "Swift Turnaround",
    description2: "Quality Assured",
  },
  {
    heading: "Fast Delivery",
    price: "35",
    description: " Urgent Translations",
    description1: " Lightning-Fast Delivery",
    description2: " Accelerated Quality",
  },
  {
    heading: "MTPE",
    price: "27",
    description: "Lorem Ipsum",
    description1: "Lorem Ipsum",
    description2: "Lorem Ipsum",
  },
];

export const CustomerFirstdata = [
  {
    imgUrl: Continuous,
    heading: "Customer Centricity",
    subheading:
      "Driven by satisfaction, our core value is ensuring every customer's unique needs are met.",
  },
  {
    imgUrl: Delivery,
    heading: "Fast Delivery",
    subheading:
      "Trust us for on-time delivery and high-quality translations, accommodating even the tightest deadlines.",
  },
  {
    imgUrl: Support,
    heading: "24/7 Customer Support",
    subheading:
      "Questions or concerns? We're here for you, anytime. Seamless service at your fingertips.",
  },
  {
    imgUrl: Guaranteeicon,
    heading: "1-Year Guarantee",
    subheading:
      "We deliver long-term value —peace of mind and satisfaction, backed by our year-long guarantee.",
  },
];

export const Slides = [
  {
    imgUrl: Personaltrans,
    heading: "Personal Translation",
    subheading:
      "Experience utmost care and confidentiality as we handcraft the translation of your personal documents.",
  },
  {
    imgUrl: Academytrans,
    heading: "Academic Translation",
    subheading:
      "Precise translations for academic research content. Preserve the integrity of your academic documents.",
  },
  {
    imgUrl: Patenttrans,
    heading: "Patent Translation",
    subheading:
      "Get the expertise you need to translate technical and legal patent documents with precision and care.",
  },
  {
    imgUrl: Litracytrans,
    heading: "Literary Translation",
    subheading:
      "Capture the essence and artistry of your literature. Let us help you bridge cultures and languages.",
  },
  {
    imgUrl: Litracytrans,
    heading: "Literary Translation",
    subheading:
      "Capture the essence and artistry of your literature. Let us help you bridge cultures and languages.",
  },
  {
    imgUrl: Litracytrans,
    heading: "Literary Translation",
    subheading:
      "Capture the essence and artistry of your literature. Let us help you bridge cultures and languages.",
  },
];

export const OneStopSolutiondata = [
  {
    heading: "Localization",
    subheading:
      "Don't just translate, ensure your content truly resonates with local cultures.",
    btn: "Dicover True Localization",
  },
  {
    heading: "Interpreting",
    subheading:
      "Don't just translate, ensure your content truly resonates with local cultures.",
    btn: "Bridge the Gap",
  },
  {
    heading: "Multilingual Solutions",
    subheading:
      "Reach global audiences with solutions like DTP, transcreation, content writing, and transcription.",
    btn: "Explore Our Offerings",
  },
  {
    heading: "Website Translation",
    subheading:
      "Make your website a global destination, seamlessly tailored for diverse audiences.",
    btn: "Globalize Your Website",
  },
];
export const TestimonilaData = [
  {
    imgUrl: Governer,
    title: "THE GOVERNOR’S ACADEMY",
    description:
      "We regularly work with Tomedes Translation Services and they are a great team. Quick, accurate turnaround for any document we've needed translated into a variety of languages. Highly recommend!",
    image: Jn,
    heading: "Jake Neuman",
    subheading: "Senior Marketing Manager",
  },
  {
    imgUrl: "",
    title: "Mojave Desert Land Trust",
    description:
      "So happy to have found Tomedes! The nonprofit I work for has used their translation services many times, and I'm always surprised by their attentiveness and quick turnaround. Highly recommend!",
    image: Jg,
    heading: "Jessica Graybill",
    subheading: "Social Media Manager",
  },
  {
    imgUrl: Abandoned,
    title: "",
    description:
      "Tomedes Translation Services has provided many translations for my team. Their work is high-quality, reasonably priced, and they are extremely responsive and easy to work with. Highly recommend!",
    image: Am,
    heading: "Alexa Mehos",
    subheading: "Grants and Contracts Manager",
  },
];

export const Deeperdata = [
  {
    recent: "Recent",
    translation: "Translation",
    legal: "Legal",
    title: "Veritatis facere rerum occaecati.",
    description:
      "Perferendis atque saepe distinctio quasi omnis. Enim vitae optio quia nesciunt architecto. Quia ex in voluptas porro maxime.",
  },
  {
    recent: "Recent",
    translation: "Translation",
    legal: "Legal",
    title: "Veritatis facere rerum occaecati.",
    description:
      "Perferendis atque saepe distinctio quasi omnis. Enim vitae optio quia nesciunt architecto. Quia ex in voluptas porro maxime.",
  },
  {
    recent: "Recent",
    translation: "Translation",
    legal: "Legal",
    title: "Veritatis facere rerum occaecati.",
    description:
      "Perferendis atque saepe distinctio quasi omnis. Enim vitae optio quia nesciunt architecto. Quia ex in voluptas porro maxime.",
  },
];
