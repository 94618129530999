import React from "react";
import Text from "components/Text";
import { Link } from "gatsby";
import rightArrowBlackSvg from "assets/translation-services/right-arrow-black.svg";
import starSvg from "assets/translation-services/star.svg";
import Intuit from "assets/Intuit.svg";
import NBC from "assets/NBCUniversal.svg";
import Ikea from "assets/Ikea.svg";
import Decathlon from "assets/decathlon.svg";
import OptimizedImage from "hooks/OptimizedImage";

const StarComp = () => {
  return (
    <>
      <div className="flex justify-center gap-[6px] pb-7">
        <img src={starSvg} alt="rating" />
        <img src={starSvg} alt="rating" />
        <img src={starSvg} alt="rating" />
        <img src={starSvg} alt="rating" />
        <img src={starSvg} alt="rating" />
      </div>
    </>
  );
};

function ClientsReviews({ data }) {
  return (
    <div className="py-28 px-6">
      <div className="w-full max-w-6xl mx-auto">
        <Text
          as="h2"
          className="text-3xl leading-[55px] review_title text-center xs:font-secondary font-semibold pb-2"
        >
          Reviews
        </Text>
        <Text
          as="h3"
          className="text-5xl leading-[60px] text-center xs:font-secondary font-bold pb-7"
        >
          4.9 out of 5 Aggregate Rating
        </Text>
        <Text
          as="p"
          className="text-lg leading-8 text-[#3D3F42] text-center font-opensans pb-11 max-w-5xl mx-auto"
        >
          Tomedes is proud to be a recognized leader in the translation
          industry, and we go that extra mile to stay that way. By reading our
          translation service reviews, you will see that our main priority is
          and always will be you.
        </Text>

        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
          <div className="border-2 rounded-xl border-black w-full px-6 py-10 flex flex-col gap-y-8">
            <div className="flex justify-between">
              <StarComp />
              <img src={Ikea} alt="Ikea" className=" w-24 h-6" />
            </div>
            <div className="text-center font-primary">
              <p className="font-bold text-black">
                "Tomedes works exceptionally well"
              </p>
              <p className="invisible">lorem ipsum</p>
              <p className="text-sm text-grayBorder">
                Tomedes works exceptionally well, responds immediately, delivers
                translations on time (or even earlier!) and is very helpful
                everytime.
              </p>
            </div>
            <div className="text-center font-primary">
              <p className="font-bold text-black">Maria José Guíñez</p>
              <p className="text-sm text-grayBorder">Communication Analyst</p>
              <p className="text-sm text-grayBorder">IKEA</p>
            </div>
          </div>
          <div className="border-2 rounded-xl border-black w-full px-6 py-10 flex flex-col gap-y-8">
            <div className="flex justify-between">
              <StarComp />
              <img src={NBC} alt="NBC" className=" w-24 h-6" />
            </div>
            <div className="text-center font-primary">
              <p className="font-bold text-black">
                "Best translation service used!"
              </p>
              <p className="invisible">lorem ipsum</p>
              <p className="text-sm text-grayBorder">
                It's a pleasure working with them, always quick in response and
                very kind. 24/7 service ensures accommodating to your crazy
                schedules.
              </p>
            </div>
            <div className="text-center font-primary">
              <p className="font-bold text-black">Denisse Best</p>
              <p className="text-sm text-grayBorder">Producer</p>
              <p className="text-sm text-grayBorder">
                NBC Universal Media LLC{" "}
              </p>
            </div>
          </div>
          <div className="border-2 rounded-xl border-black w-full px-6 py-10 flex flex-col gap-y-8">
            <div className="flex justify-between">
              <StarComp />
              <img src={Decathlon} alt="decathlon" className=" w-24 h-6" />
            </div>
            <div className="text-center font-primary h-[128px]">
              <p className="font-bold text-black">“Great service!"</p>
              <p className="invisible">lorem ipsum</p>
              <p className="text-sm text-grayBorder">
                It is always a pleasure to work with Tomedes.
              </p>
              <p className="invisible">lorem ipsum</p>
            </div>
            <div className="text-center font-primary">
              <p className="font-bold text-black">Noa Pitlik </p>
              <p className="text-sm text-grayBorder">
                Content and Localisation Leader{" "}
              </p>
              <p className="text-sm text-grayBorder">Decathlon Israel </p>
            </div>
          </div>
        </div>

        <Link
          to="/reviews.php"
          className="text-24 review_title text-center xs:font-secondary font-medium flex justify-center items-center space-x-3 py-14"
        >
          <p>See all reviews</p>
          <p>&#x2192;</p>
          {/* <img src={rightArrowBlackSvg} alt="cta" /> */}
        </Link>

        <h2 className="text-5xl leading-[60px] text-center xs:font-secondary font-bold pb-7 text-black font-primary">
          Join Thousands of Satisfied Clients
        </h2>
        <p className="ext-lg leading-8 text-[#3D3F42] text-center font-opensans pb-11 max-w-5xl mx-auto">
          Establishments worldwide have learned to count on Tomedes thanks to
          our wide range of knowledge and consistent aims towards excellence.
          Our years of dedication and commitment to every project has led us to
          work hand-in-hand with Fortune 500 companies as well as
          small-to-medium businesses all around the globe.
        </p>
        <div className="mx-auto pt-10 text-center">
          <div className="grid gap-12 max-w-lg mx-auto  grid-cols-2 md:grid-cols-3 md:gap-10 lg:flex lg:flex-wrap lg:justify-center lg:space-x-6 lg:space-y-4 lg:max-w-4xl place-items-center place-content-center">
            <div className="self-end">
              <OptimizedImage
                src="https://tomedes.gumlet.io/assets/clients/amazon.svg"
                alt="amazon"
                className="w-24 h-auto"
              />
            </div>
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/google.svg"
              alt="google"
              className="w-20 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/microsoft.svg"
              alt="microsoft"
              className="w-24 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/youtube.svg"
              alt="youtube"
              className="w-24 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/bloomberg.svg"
              alt="bloomberg"
              className="w-24 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/spotify.svg"
              alt="spotify"
              className="w-20 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/wix.svg"
              alt="wix"
              className="w-20 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/hsbc.svg"
              alt="hsbc"
              className="w-24 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/images/canon.svg"
              alt="canon"
              className="w-24 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/mgm.png"
              alt="mgm"
              className="w-11 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/code.svg"
              alt="code"
              className="w-9 h-auto"
            />
            <OptimizedImage
              src="https://tomedes.gumlet.io/assets/clients/sap.svg"
              alt="sap"
              className="w-12 h-auto"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
export default ClientsReviews;
